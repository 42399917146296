import {useState} from 'react';
import logo from './logo.svg';
import './App.css';
import axios from 'axios';

function App() {
  const key = "1234355";
  const secretKey = "abcdefg";
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: ''
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { firstName, lastName, email } = formData;
    try {
      const response = await axios.post('/api/campaign', { firstName, lastName, email });
      console.log('Response:', response);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  return (
    <div className="App">
        <div className="form-container">
            <h1>Formulario de Campaña</h1>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="firstName" className="form-label">Nombre</label>
                    <input type="text" className="form-control" id="firstName" name="firstName" required onChange={handleInputChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="lastName" className="form-label">Apellido</label>
                    <input type="text" className="form-control" id="lastName" name="lastName" required onChange={handleInputChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input type="email" className="form-control" id="email" name="email" required onChange={handleInputChange} />
                </div>
                <button type="submit" className="btn btn-primary">Enviar</button>
            </form>
        </div>
    </div>

  );
}

export default App;
